import React, {useEffect, useState} from "react";
import {Table, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";

export default function Results({auth}) {

    const [results, setResults] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        getResults();
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getResults() {
        let dataResults = null;
        axios.get('https://davecode.stronawcal.pl/debiutynadal/api2/results').then(function (response) {
            dataResults = response.data.sort(comparator)
            setResults(dataResults);
        });
    }

    function getUsers() {
        axios.get('https://davecode.stronawcal.pl/debiutynadal/api/player').then(function (response) {
            setPlayers(response.data);
        });
    }

    const deleteUser = (id) => {
        axios.delete(`https://davecode.stronawcal.pl/debiutynadal/api2/result/${id}/delete`).then(function (response) {
            getResults();
        });
    }

    const comparator = (a, b) => {
        if (a.date_create > b.date_create)
            return -1;
        if (a.date_create < b.date_create)
            return 1;
        return 0;
    }

    const namePlayers = (id) => {
        let player = players.filter(item => item.id === id)
        if (player.length === 0) {
            return;
        }
        return (
            <span>{player[0].name}</span>
        )
    }

    return (
        <div>
            <h4 className="mb-4">Wyniki meczów</h4>
            {auth &&
            <Link to="dodaj">Dodaj wynik</Link>
            }
            <div style={{maxWidth: 1280, margin: 'auto'}}>
                <Table className="container-table" striped bordered hover responsive="lg" size="sm">
                    <thead className='header-column'>
                    <tr>
                        <th>Nr.</th>
                        <th>Imię i Nazwisko</th>
                        <th>Imię i Nazwisko</th>
                        <th>Set 1</th>
                        <th>Set 2</th>
                        <th>Set 3</th>
                        <th>Data</th>
                        <th>Uwagi</th>
                        {auth &&
                        <th>Akcja</th>
                        }

                    </tr>
                    </thead>

                    <tbody>
                    {results.map((result, index) =>
                        <tr key={index}>
                            <td>{results.length - index}</td>
                            <td className='column-win'>{namePlayers(result.player_win)}</td>
                            <td className='column-loss'>{namePlayers(result.player_loss)}</td>
                            <td>{result.set_1a} : {result.set_1b}</td>
                            <td>{result.set_2a} : {result.set_2b}</td>
                            <td>{result.set_3a} : {result.set_3b}</td>
                            <td>{result.date_create}</td>
                            <td>{result.comments}</td>
                            {auth &&
                            <td>
                                <Button variant="danger" size="sm"
                                        onClick={() => deleteUser(result.id)}>Usuń</Button>
                            </td>
                            }
                        </tr>
                    )}
                    </tbody>

                </Table>
                {results.length === 0 && <span className="empty-table">Brak wyników</span>}
            </div>
        </div>
    )
}