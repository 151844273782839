import {useState} from "react";
import axios from "axios";
import {Button, Form, Row} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function CreatePlayer() {

    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://davecode.stronawcal.pl/debiutynadal/api/player/save', inputs).then(function (response) {
            if(response.status === 200) {
                navigate('/');
            }
        })
            .catch(function (error) {
                console.log(error);
            });


    }

    return (
        <>
            <h4>Dodaj zawodnika</h4>
            <div className="form-result">
                <Form onSubmit={handleSubmit}>

                    <Row className="mb-4">

                        <Form.Label>Imię:</Form.Label>
                        <Form.Control
                            name="name"
                            placeholder="Imię i Nazwisko"
                            required
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Wartość zawodnika:</Form.Label>
                        <Form.Control
                            name="value_player"
                            placeholder="Wartość zawodnika"
                            required
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Telefon:</Form.Label>
                        <Form.Control
                            name="phone"
                            placeholder="Telefon"
                            required
                            onChange={handleChange}
                        />

                        <Form.Label className="mt-2">Uwagi:</Form.Label>
                        <Form.Control
                            name="comments"
                            placeholder="Uwagi"
                            onChange={handleChange}
                        />
                    </Row>

                    <Button variant="success" type="submit">
                        Dodaj
                    </Button>
                </Form>
            </div>
        </>
    )
}

